
    import Vue, { PropOptions } from 'vue';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { Match, SharedConstants } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'DatingMatchRow',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            match: {
                type: Object,
                required: true,
            } as PropOptions<Match>,
        },

        data() {
            return {
                headerText: '',
                monthDayText: '',
                checked: false,
            };
        },

        watch: {
            match: {
                immediate: true,
                deep: true,
                handler(newVal: Match, oldVal: Match) {
                    if (newVal) {
                        if (newVal.date) {
                            const lDate = LuxonDateTime.fromISO(newVal.date);
                            this.headerText = 'Date ' + lDate.toFormat('EEEE');
                            this.monthDayText = lDate.toFormat('MMMM d');
                        } else if (newVal.unmatchReason == SharedConstants.DATING_UNMATCH_REASON_RESCHEDULE) {
                            this.headerText = 'Reschedule';
                        } else {
                            this.headerText = 'Pending';
                        }
                    }
                },
            },
        },

        computed: {
            lastMessageIsFromPartner(): boolean {
                return this.match.partnerUserId == this.match.chatLastMessageAuthorUserId;
            },
            lastMessageFormated(): string {
                if (this.match.chatLastMessage == '') return '';
                const prefix = this.lastMessageIsFromPartner ? this.match.partnerName : 'You';
                var message = this.match?.chatLastMessage ?? '';
                switch (this.match.chatLastMessage) {
                    case SharedConstants.CHAT_POST_MESSAGE_TYPE_ASKED_OUT:
                        message = 'Asked out';
                        break;
                    case SharedConstants.CHAT_POST_MESSAGE_TYPE_ACCEPTED:
                        message = 'Accepted date request';
                        break;
                    case SharedConstants.CHAT_POST_MESSAGE_TYPE_SHARED_AVAILABILITY:
                        message = 'Shared Availability';
                        break;
                    case SharedConstants.CHAT_POST_MESSAGE_TYPE_SHARED_PHONE_NUMBER:
                        message = 'Shared Contact';
                        break;
                }
                return prefix + ': ' + message;
            },
        },

        methods: {},
    });
